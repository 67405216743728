import Vue from 'vue';
import App from './app.vue';
import './common/common.less';
import vuetify from './plugins/vuetify';
import router from './router';
import base64js from 'base64-js';
Vue.config.productionTip = false;
window.base64js = base64js;
window.Zlib = require('zlibjs/bin/zlib.min.js').Zlib;
new Vue({
    router,
    vuetify,
    render: (h) => h(App)
}).$mount('#app');
