import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
    {
        path: '/roadmaps/:subId/:roadmapId',
        name: 'RoadmapPortal',
        component: () => import('../views/roadmap-portal.vue'),
        meta: {
            title: 'Pendo roadmaps'
        }
    },
    {
        path: '/p/:apiKey/:portalId',
        name: 'VocPortal',
        component: () => import('../views/voc-portal.vue'),
        props: true
    }
];

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Portal';
    next();
});

export default router;
